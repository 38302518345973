<template>
  <el-dialog
    :title="isUpdate?'修改试题':'添加试题'"
    :visible.sync="visible"
    width="60%"
    :destroy-on-close="true"
    :lock-scroll="false"
    custom-class="ele-dialog-form"
    ref="eleDialogForm"
    @update:visible="updateVisible">
    <div class="word_logic">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <!--描述-->
        <el-form-item label="描述" prop="content">
          <el-col :span="5">
            <el-input v-model="ruleForm.content" placeholder="请输入描述"></el-input>
          </el-col>

        </el-form-item>
        <!--      等级-->
        <el-form-item label="等级" prop="level_id">
          <el-select v-model="ruleForm.level_id" placeholder="请选择等级">
            <el-option
              v-for="item in levelList"
              :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!--时间-->
        <el-form-item label="答题时长" prop="answer_time">
          <el-col :span="5">
            <el-input v-model="ruleForm.answer_time" oninput = "value=value.replace(/[^\d.]/g,'')" placeholder="请输入答题时长"></el-input>
            <span style="color: red">*单位：分钟</span>
          </el-col>
        </el-form-item>
        <!--分数-->
        <el-form-item label="词语分数" prop="word_score">
          <el-col :span="5">
            <el-input v-model.number="ruleForm.word_score" placeholder="请输入分数"></el-input>
          </el-col>

        </el-form-item>
        <!--分数-->
        <el-form-item label="概括分数" prop="gaikuo_score">
          <el-col :span="5">
            <el-input v-model.number="ruleForm.gaikuo_score" placeholder="请输入分数"></el-input>
          </el-col>

        </el-form-item>

        <!--词语描述-->
        <el-form-item label="词语描述" prop="question1">
          <el-col :span="5">
            <el-input v-model="ruleForm.question1" placeholder="请输入描述"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button v-show="this.ruleForm.terms.length > 1" type="danger" @click="reduceTerms">删除</el-button>
          <el-button type="primary" @click="addTerms">新增</el-button>
        </el-form-item>

        <!--      词语-->
        <el-form-item label="词语"
                      v-for="(item,index) in ruleForm.terms"
                      :key="item.key"
                      :prop="'terms.' + index + '.term'"
                      :rules="{
                      required: true, message: '词语不能为空', trigger: 'blur'
                    }"
        >
          <el-col :span="5">
            <el-input v-model="item.term" placeholder="请输入词语"></el-input>
          </el-col>
          <el-col :span="4" style="margin-left: 20px;">
            <el-form-item :prop="'terms.' + index + '.radio'" :rules="{
                      required: true, message: '请至少选择一个 ', trigger: 'blur'
                    }">
              <el-radio-group v-model="item.radio">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

        </el-form-item>
        <!--概括描述-->
        <el-form-item label="概括描述" prop="question2">
          <el-col :span="5">
            <el-input v-model="ruleForm.question2" placeholder="请输入描述"></el-input>
          </el-col>

        </el-form-item>
        <el-form-item>
          <el-button v-show="ruleForm.generalizations.length > 1" type="danger" @click="reduceGeneralization">删除</el-button>
          <el-button type="primary" @click="addGeneralization">新增</el-button>
        </el-form-item>

        <!--      概括-->
        <el-form-item label="概括"
                      v-for="(item,index) in ruleForm.generalizations" :key="item.key"
                      :prop="'generalizations.' + index + '.generalization'"
                      :rules="{
                      required: true, message: '概括不能为空', trigger: 'blur'
                    }"
        >
          <el-col :span="5">
            <el-input v-model="item.generalization" placeholder="请输入概括"></el-input>
          </el-col>

          <el-col :span="4" style="margin-left: 20px;">
            <el-form-item :prop="'generalizations.' + index + '.radio'" :rules="{
                      required: true, message: '请至少选择一个 ', trigger: 'blur'
                    }">

              <el-radio-group v-model="item.radio">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>


        </el-form-item>
        <!--提交      -->

        <!--      <el-form-item style="text-align: left; margin-top: 100px;">-->
        <!--        <el-button type="primary" @click="submitForm('ruleForm')">创建</el-button>-->
        <!--        <el-button @click="resetForm('ruleForm')">重置</el-button>-->
        <!--      </el-form-item>-->
      </el-form>

    </div>
    <div slot="footer">
      <el-button
        @click="resetForm">取消
      </el-button>
      <el-button
        type="primary"
        @click="save"
        :loading="loading">保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor';

export default {

  name: 'LogicEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object || Boolean,
    // 父组件方法，关闭弹框
    openEdit: Function
  },
  components: {TinymceEditor},
  data() {
    return {
      value: '',
      init: {},
      ruleForm: {
        score: '',
        level_id: '',
        terms: [{term: '', radio: 0}],
        generalizations: [{generalization: '', radio: 0}],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 等级下拉数据
      levelList: [],
      rules: {
        level_id: [
          {required: true, message: '请选择等级', trigger: 'blur'},
        ],
        word_score: [
          {required: true, message: '请输入词语分数', trigger: 'blur'},
          /* {type: 'number', message: '分数必须为数字值'}*/
        ],
        gaikuo_score: [
          {required: true, message: '请输入概括分数', trigger: 'blur'},
        ],
        answer_time: [
          {required: true, message: '请输入答题时间', trigger: 'blur'},
        ],
        question1: [
          {required: true, message: '请输入词语描述', trigger: 'blur'},
        ],
        terms: [
          {required: true, message: '请输入词语', trigger: 'change'},
        ],
        question2: [
          {required: true, message: '请输入概括描述', trigger: 'change'},
        ],
        generalization: [
          {required: true, message: '请输入概括', trigger: 'change'},
        ],
        radio: [
          {required: true, message: '请选择至少一个', trigger: 'change'},
        ]

      }
    };

  },
  watch: {
    //deep: true,
    data() {
      if (this.data) {
        this.$http['get']('/Admin/worldLogicInfo/' + this.data.id).then(res => {
          this.data.terms = res.data.data.term;
          this.data.generalizations = res.data.data.generalization;
          this.data.word_score = res.data.data.word_score;
          this.data.gaikuo_score = res.data.data.gaikuo_score;
          this.data.question1 = res.data.data.question1;
          this.data.question2 = res.data.data.question2;
          this.ruleForm = Object.assign({}, this.data);
          this.isUpdate = true;
        })
      } else {
        this.ruleForm = {
          score: '',
          level_id: '',
          terms: [{term: '', radio: 0}],
          generalizations: [{generalization: '', radio: 0}],
        },
          this.isUpdate = false;
      }

    }
  },
  mounted() {
    this.init = {
      height: 400,
    }
    this.queryLevelList();  // 查询角色列表
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.loading = true;
          console.log(this.isUpdate)
          this.$http[this.isUpdate ? 'put' : 'post']
          (this.isUpdate ? '/Admin/worldLogic/' + this.ruleForm.id : '/Admin/worldLogic',
            this.ruleForm).then(res => {
            this.loading = false;
            if (res.data.code === 200) {
              this.$message({type: 'success', message: res.data.msg});
              // 是添加框
              if (!this.isUpdate) {
                this.ruleForm = {
                  score: '',
                  level_id: '',
                  terms: [{term: '', radio: 0}],
                  generalizations: [{generalization: '', radio: 0}],
                }
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    // 添加词汇
    addTerms() {
      //this.isUpdate = false
      this.ruleForm.terms.push({term: '', radio: 0, key: Date.now()})
    },
    // 减少词汇
    reduceTerms() {
      if(this.ruleForm.terms.length > 1){
        this.ruleForm.terms.pop()
      }
    },
    // 添加概括
    addGeneralization() {
      this.ruleForm.generalizations.push({generalization: '', radio: 0, key: Date.now()})
    },
    // 减少概括
    reduceGeneralization() {
      if(this.ruleForm.generalizations.length > 1){
        this.ruleForm.generalizations.pop()
      }
    },
    // 取消
    resetForm() {
      // 调用父组件方法 隐藏弹框
      this.openEdit(null)
    },
    // 删除
    deleteProblem(index) {
      this.ruleForm.domains.splice(index, 1)
    },
    // 添加
    addProblem() {
      this.ruleForm.domains.push({
        problem: '',
        options: '',
        radio: 0,
        key: Date.now()
      })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
    /* 查询等级列表 */
    queryLevelList() {
      this.$http.get('/Admin/level').then(res => {

        if (res.data.code === 200) {
          this.levelList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },

  }
}
</script>
<style lang="scss">
.word_logic {
  .demo-ruleForm {
    margin-top: 30px;

    .form_add_problem {
      position: relative;

      .add_problem,
      .delete_problem {
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translateY(-50%);
      }

      .delete_problem {
        right: 40%;
      }
    }
  }

  .edit_form {
    padding: 0 100px;
  }
}

</style>
